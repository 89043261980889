import { ArrowLeft, Download } from "react-feather";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import CalendarAuditCSVTable from "./table";
import DefaultSwitch from "../defaultSwitch";
import CustomButtonV2 from "../buttons/customButtonV2";
import {
  BLUE_BUTTON,
  SECOND_IN_MS,
  WHITE_BUTTON,
} from "../../services/globalVariables";
import CustomSelectV2, { SELECT_VARIANTS } from "../select/selectV2";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import {
  getCalendarIsPrimary,
  getCalendarProviderId,
  getCalendarUserCalendarID,
} from "../../services/calendarAccessors";
import { getCalendarUserEmail } from "../../lib/calendarFunctions";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
} from "../../services/typeGuards";
import {
  formatEventsArrayForReactBigCalendar,
  isRecurringEvent,
} from "../../lib/eventFunctions";
import { useCalendarAudit } from "../../services/stores/calendarAuditStore";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import {
  fetchEvents,
  getDateRangeForTimeFrameOption,
  getOnePagerMinsSaved90Days,
  splitIntoFetchWindow,
} from "./helperFunctions";
import {
  getMatchingExecutiveUserFromCalendar,
  isCalendarExecutiveCalendar,
} from "../../services/maestroFunctions";
import {
  getMatchingUserFromAllUsers,
  getUserEmail,
  getUserToken,
  isEmailExecEmail,
} from "../../lib/userFunctions";
import {
  getEventMasterEventID,
  getEventRecurrence,
} from "../../services/eventResourceAccessors";
import { fetcherGet } from "../../services/fetcherFunctions";
import { constructRequestURLV2 } from "../../services/api";
import DatePicker, { DATE_PICKER_TIME_FRAME } from "./datePicker";
import {
  constructOnePagerData,
  minToDaysHoursMins,
  shouldDisplayCalendarAuditIntro,
} from "../../lib/calendarAudit/functions";
import OnePager from "./onePager";
import { handleError, hasEventPreventDefault } from "../../services/commonUsefulFunctions";
import {
  createCSVForAuditEvents,
  downloadFilesAsZip,
} from "../../lib/fileFunctions";
import { useOutlookCategoriesStore } from "../../services/stores/outlookCategoriesStore";
import ExecutiveLabel from "../../../components/executiveLabel";
import { HOME_PATH } from "../../services/routingFunctions";
import { CALENDAR_AUDIT_CLASSNAMES, CALENDAR_AUDIT_INTRO_MODAL_TOOLTIP, CALENDAR_AUDIT_READY_MODAL_TOOLTIP } from "../../lib/calendarAudit/variables";
import EventModalPopup from "../eventModalPopup";
import {
  determineDefaultModalStyle,
  MODAL_OVERLAY_Z_INDEXES,
} from "../../lib/modalFunctions";
import CalendarAuditConfirmationModal from "./confirmationModal";
import usePrevious from "../specialComponents/usePrevious";
import UndoButton from "../icons/undo";
import {
  ConfirmationModalData,
  EventToRender,
  MasterEventRecurrences,
  ProposedChanges,
} from "../../lib/calendarAudit/accessors";
import TimeCounter from "./timeCounter";
import broadcast from "../../broadcasts/broadcast";
import { BROADCAST_VALUES } from "../../lib/broadcastValues";
import { CalendarAuditIntroModal } from "./introModal";
import { shouldShowCalendarAudit } from "../../lib/featureFlagFunctions";
import { FEATURE_TRACKING_ACTIONS, trackFeatureUsage } from "../tracking";

function CalendarAuditCSV() {
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const componentIsMounted = useIsMounted();
  const currentTimeZone = useSelector((state) => state.currentTimeZone);
  const currentUser = useSelector((state) => state.currentUser);
  const { eventsByUser, setEventsByUser } = useCalendarAudit();
  const history = useHistory();
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const outlookCategories = useOutlookCategoriesStore(
    (state) => state.outlookCategories,
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const pageRef = useRef<HTMLDivElement | null>(null);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {
    /* Return if user is supposed to be gated */
    if (!shouldShowCalendarAudit(masterAccount)) {
      history.push("/home");
    }

    /* Pause mousetrap to prevent oddities */
    broadcast.publish(BROADCAST_VALUES.DISABLE_HOT_KEYS);

    return () => {
      broadcast.publish(BROADCAST_VALUES.ENABLE_HOT_KEYS);
    };
  }, []);

  /* Handle first time landing */
  const [flashProposed, setFlashProposed] = useState(false);
  useEffect(() => {
    broadcast.publish(
      BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED,
      CALENDAR_AUDIT_READY_MODAL_TOOLTIP,
    );

    /* Prevent request from delaying modal close by updating on flashProposed set to true */
    if (flashProposed) {
      broadcast.publish(
        BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED,
        CALENDAR_AUDIT_INTRO_MODAL_TOOLTIP,
      );
    }
  }, [flashProposed]);

  /* If columns are flashing, set a 5s delay to turn off */
  useEffect(() => {
    if (flashProposed) {
      setTimeout(() => {
        if (!componentIsMounted.current) {
          return;
        }

        setFlashProposed(false);
      }, 10 * SECOND_IN_MS);
    }
  }, [flashProposed]);

  /*********/
  /* Table */
  /*********/

  const [onlyShowRecurring, setOnlyShowRecurring] = useState(false);
  const [proposedChanges, setProposedChanges] = useState<ProposedChanges>({});
  const [masterEventRecurrences, setMasterEventRecurrences] =
    useState<MasterEventRecurrences>({});
  const [page, setPage] = useState(1);
  const [confirmationModalData, setConfirmationModalData] =
    useState<ConfirmationModalData | null>(null);
  const [isExporting, setIsExporting] = useState(false);

  /* Get the calendars for userSelect */
  const primaryCalendars = useMemo(
    () =>
      Object.values(allCalendars)
        .filter((calendar) => getCalendarIsPrimary(calendar))
        .sort((calendarA, calendarB) => {
          if (getCalendarUserEmail(calendarA) === getUserEmail(currentUser)) {
            return -1;
          }

          if (getCalendarUserEmail(calendarB) === getUserEmail(currentUser)) {
            return 1;
          }

          const calendarAUserEmail = getCalendarUserEmail(calendarA);
          const calendarBUserEmail = getCalendarUserEmail(calendarB);

          return calendarAUserEmail.localeCompare(calendarBUserEmail);
        })
        .map((calendar) => ({
          label: getCalendarUserEmail(calendar),
          value: calendar, // do not use calendarObject here because then we can't use calendar accessors
        })),
    [allCalendars],
  );

  /* Find first exec or default to self (primaryCalendars[0]) */
  const getDefaultCalendar = () => {
    return (
      primaryCalendars.find((calendarOption) =>
        isCalendarExecutiveCalendar({
          calendar: calendarOption.value,
          allLoggedInUsers,
        }),
      ) ?? primaryCalendars[0]
    );
  };
  const [selectedCalendar, setSelectedCalendar] = useState(getDefaultCalendar);

  /* Group recurring events for render */
  const { eventsToRender, groupedRecurrences } = useMemo(() => {
    const events = eventsByUser[selectedCalendar.label]?.events || [];
    const eventsToRender = [] as EventToRender[];
    const groupedRecurrences = {} as Record<string, VimcalEvent[]>;

    events.forEach((event) => {
      if (isRecurringEvent(event)) {
        /* Construct recurring events object to group same recurrence */
        const masterEventID = getEventMasterEventID(event);
        const existingRecurrences = groupedRecurrences[masterEventID];
        if (!isEmptyArrayOrFalsey(existingRecurrences)) {
          existingRecurrences.push(event);
          groupedRecurrences[masterEventID] = existingRecurrences;
          return;
        }

        groupedRecurrences[masterEventID] = [event];
        eventsToRender.push({ isGroupedRecurrence: true, masterEventID });
        return;
      }

      if (!onlyShowRecurring) {
        eventsToRender.push(event);
      }
    });

    return { eventsToRender, groupedRecurrences };
  }, [eventsByUser, onlyShowRecurring, selectedCalendar]);
  const previousGroupedRecurrences = usePrevious(groupedRecurrences);
  const hasProposedChanges = useMemo(
    () => !isEmptyObjectOrFalsey(proposedChanges),
    [proposedChanges],
  );

  /***************/
  /* Date picker */
  /***************/

  const [option, setOption] = useState(DATE_PICKER_TIME_FRAME.LAST_QUARTER);
  const { startDate: initialStartDate, endDate: initialEndDate } =
    getDateRangeForTimeFrameOption(option);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  /* User for fetch, header, onePager, and table  */
  const matchingUser = useMemo(() => {
    const calendarValue = selectedCalendar.value;
    return isCalendarExecutiveCalendar({
      calendar: calendarValue,
      allLoggedInUsers,
    })
      ? getMatchingExecutiveUserFromCalendar({
        calendar: calendarValue,
        allLoggedInUsers,
      })
      : getMatchingUserFromAllUsers({
        allUsers: allLoggedInUsers,
        userEmail: getCalendarUserEmail(calendarValue),
      });
  }, [allLoggedInUsers, selectedCalendar]);

  /* Fetch events */
  useEffect(() => {
    const calendarObject = selectedCalendar?.value;
    const controller = new AbortController();

    if (!isEmptyObjectOrFalsey(calendarObject)) {
      // TODO: Use cache if range is the same and events exist
      // TODO: also refetch if date switches.
      // TODO: check if we already fetched for this range and user
      setPage(1);
      fetchEventsForWindow(controller); // TODO: remove
    }

    return () => {
      controller.abort();
    };
  }, [selectedCalendar, endDate, startDate]);

  /* Fetch master recurrence */
  useEffect(() => {
    const currentMasterEventIDs = Object.keys(groupedRecurrences);
    const previousMasterEventIDs = Object.keys(
      previousGroupedRecurrences || {},
    );
    const newMasterEventIDs = currentMasterEventIDs.filter(
      (masterEventIDs) => !previousMasterEventIDs.includes(masterEventIDs),
    );

    if (!isEmptyArrayOrFalsey(newMasterEventIDs)) {
      fetchMasterEvents(newMasterEventIDs);
    }
  }, [groupedRecurrences, previousGroupedRecurrences]);

  const onePagerData = useMemo(
    () =>
      constructOnePagerData({
        endDate,
        eventsToRender,
        groupedRecurrences,
        masterAccount,
        matchingUser,
        masterEventRecurrences,
        proposedChanges,
        startDate,
      }),
    [
      endDate,
      eventsToRender,
      groupedRecurrences,
      masterAccount,
      matchingUser,
      masterEventRecurrences,
      proposedChanges,
      startDate,
    ],
  );

  /******************/
  /* Fetch Requests */
  /******************/

  const fetchEventsForWindow = async (controller) => {
    const syncWindows = splitIntoFetchWindow({
      startDate,
      endDate,
      windowSize: 7,
    });
    const calendarValue = selectedCalendar.value;
    const fetchedEvents: VimcalEvent[] = [];

    let hasError = false;
    setIsLoadingEvents(true);
    for (const syncWindow of syncWindows) {
      const { windowStart, windowEnd } = syncWindow;
      const response = await fetchEvents({
        allCalendars,
        controller,
        currentTimeZone,
        endDate: windowEnd,
        startDate: windowStart,
        userCalendarIDs: [getCalendarUserCalendarID(calendarValue)],
        userEmail: getUserEmail(matchingUser),
      });
      if (!componentIsMounted.current) {
        return;
      }
      if (!isEmptyArrayOrFalsey(response?.events)) {
        fetchedEvents.push(
          ...formatEventsArrayForReactBigCalendar({
            calendarId: null,
            currentTimeZone,
            events: response?.events,
            isPreviewOutlookEvent: false,
          }),
        );
      } else {
        hasError = true;
      }
      const updatedEventsByUser = {
        ...eventsByUser,
        [getUserEmail(matchingUser)]: {
          events: fetchedEvents,
          lastFetched: new Date(),
          fetchRange: {
            start: windowStart,
            end: windowEnd,
          },
          hasError,
        },
      };
      setEventsByUser(updatedEventsByUser);
    }
    setIsLoadingEvents(false);
  };

  const fetchMasterEvents = (newMasterEventIDs) => {
    const calendarValue = selectedCalendar.value;
    newMasterEventIDs.forEach(async (masterEventID) => {
      const response = await fetcherGet({
        email: getUserEmail(matchingUser),
        url: constructRequestURLV2(
          `calendars/${getCalendarUserCalendarID(
            calendarValue,
          )}/events/${masterEventID}`,
        ),
      });

      if (response && !isEmptyObjectOrFalsey(response?.event)) {
        setMasterEventRecurrences((existingMasterEventRecurrences) => ({
          ...existingMasterEventRecurrences,
          [masterEventID]: getEventRecurrence(response.event) || "",
        }));
      }
    });
  };

  /************/
  /* Handlers */
  /************/

  const handleExportClick = async (e) => {
    try {
      hasEventPreventDefault(e);
      setIsExporting(true);

      /* Track the export */
      trackFeatureUsage({
        action: `${FEATURE_TRACKING_ACTIONS.CALENDAR_AUDIT}::export`,
        userToken: getUserToken(currentUser),
      });

      /* Array for download */
      const zipFileArray = [] as { name: string; content: Blob; type: string }[];
      const pageHeight = 1123;
      const pageWidth = 794;

      if (pageRef.current && !isEmptyObjectOrFalsey(proposedChanges)) {
        const pdf = new jsPDF({
          compress: true,
          format: [pageWidth, pageHeight],
          orientation: "portrait",
          unit: "px",
        });

        /* Capture the div as an image */
        const canvas = await html2canvas(pageRef.current, { scale: 2 });
        const imageData = canvas.toDataURL("image/jpeg");

        const imageHeight = (canvas.height * pageWidth) / canvas.width;
        let remainingHeight = imageHeight;
        let position = 0;

        pdf.addImage(imageData, "JPEG", 0, 0, pageWidth, imageHeight);
        remainingHeight -= pageHeight;

        /* Split the image into their own pages */
        while (remainingHeight > 0) {
          position = remainingHeight - imageHeight;
          pdf.addPage();
          pdf.addImage(imageData, "JPEG", 0, position, pageWidth, imageHeight);
          remainingHeight -= pageHeight;
        }

        /* Output PDF as blob to add to ZIP */
        const pdfBlob = pdf.output("blob");
        zipFileArray.push({
          name: "vimcal-audit.pdf",
          content: pdfBlob,
          type: "application/pdf",
        });
      }

      /* CSV File */
      const csv = createCSVForAuditEvents({
        allCalendars,
        allLoggedInUsers,
        eventsToRender,
        groupedRecurrences,
        masterAccount,
        masterEventRecurrences,
        onlyShowRecurring,
        proposedChanges,
        user: matchingUser,
      });
      const csvBlob = new Blob([csv], { type: "text/csv" });

      if (csvBlob) {
        zipFileArray.push({
          name: "vimcal-audit.csv",
          content: csvBlob,
          type: "",
        });
      }

      const formattedStart = format(startDate, "MM_dd_yyyy");
      const formattedEnd = format(endDate, "MM_dd_yyyy");
      downloadFilesAsZip(
        zipFileArray,
        `vimcal_audit_${formattedStart}-${formattedEnd}.zip`,
      );
      setIsExporting(false);
    } catch (e) {
      handleError(e);
      setIsExporting(false);
    }
  };

  const handleReturnClick = () => {
    if (hasProposedChanges) {
      setConfirmationModalData({
        content:
          "Are you sure you want to close Executive Calendar Audit? Your existing changes will be lost.",
        label: "Close",
        onClose: () => setConfirmationModalData(null),
        onConfirm: () => {
          if (!componentIsMounted.current) {
            return;
          }

          trackFeatureUsage({
            action: `${FEATURE_TRACKING_ACTIONS.CALENDAR_AUDIT}::edit_${changeCount}_times`,
            userToken: getUserToken(currentUser),
          });

          setConfirmationModalData(null);
          history.push(`/${HOME_PATH}`);
        },
      });
      return;
    }

    trackFeatureUsage({
      action: `${FEATURE_TRACKING_ACTIONS.CALENDAR_AUDIT}::edit_${changeCount}_times`,
      userToken: getUserToken(currentUser),
    });

    history.push(`/${HOME_PATH}`);
  };

  const handleRevertClick = () => {
    if (hasProposedChanges) {
      setConfirmationModalData({
        content: "Are you sure you want to revert all changes?",
        label: "Revert",
        onClose: () => setConfirmationModalData(null),
        onConfirm: () => {
          if (!componentIsMounted.current) {
            return;
          }

          trackFeatureUsage({
            action: `${FEATURE_TRACKING_ACTIONS.CALENDAR_AUDIT}::revert`,
            userToken: getUserToken(currentUser),
          });

          setProposedChanges({});
          setConfirmationModalData(null);
        },
      });
      return;
    }
  };

  /******************/
  /* Render Methods */
  /******************/

  const renderHeader = () => {
    const minsSaved90Days = getOnePagerMinsSaved90Days(onePagerData);
    const { days, hours, minutes } = minToDaysHoursMins(minsSaved90Days);
    const isTimeSaved = minsSaved90Days >= 0;

    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div
            className={classNames(
              "default-hover-blur-button xl-3-blur",
              "duration-200",
              "flex items-center justify-center mr-2",
              "h-12 rounded-lg w-12",
              "calendar-audit-back-button",
            )}
            onClick={handleReturnClick}
          >
            <ArrowLeft className="font-weight-400 text-white" size={20} />
          </div>
          <div className="flex-col justify-center">
            <div className="font-medium font-size-16">
              Executive Calendar Audit
            </div>
            <div className="calendar-audit-sub-header whitespace-break-spaces">
              Input new meeting durations and frequencies below to calculate
              future time savings for your exec
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div
            className={
              classNames(
                "calendar-audit-time-saved-container",
                isTimeSaved ? "" : "time-lost",
              )
            }>
            <div className="flex items-center">
              {days ? <TimeCounter label="d" value={days} /> : null}
              {hours ? <TimeCounter label="hr" value={hours} /> : null}
              <TimeCounter label="min" value={minutes} />
            </div>
            <div className="whitespace-nowrap">{isTimeSaved ? "saved" : "added"} over 90 days</div>
          </div>
        </div>
      </div>
    );
  };

  /******************************/
  /* Calendar select components */
  /******************************/

  const renderSelectedUser = ({ data, innerProps }) => {
    const userEmail = data.label;
    const isExecEmail = isEmailExecEmail({
      email: userEmail,
      masterAccount,
      allLoggedInUsers,
    });

    return (
      <div
        className="calendar-audit-user-select-single-value gap-2"
        {...innerProps}
      >
        <div className="truncate">{userEmail}</div>
        {isExecEmail ? <ExecutiveLabel /> : null}
      </div>
    );
  };

  const renderUserOption = ({ data, innerProps, setValue }) => {
    const userEmail = data.label;
    const isExecEmail = isEmailExecEmail({
      email: userEmail,
      masterAccount,
      allLoggedInUsers,
    });
    const currentCalendarProviderID = getCalendarProviderId(
      selectedCalendar.value,
    );
    const optionCalendarProviderID = getCalendarProviderId(data.value);

    return (
      <div
        {...innerProps}
        className={classNames(
          "calendar-audit-user-select-option",
          currentCalendarProviderID === optionCalendarProviderID
            ? "calendar-audit-user-select-option-selected"
            : "",
        )}
        onClick={() => setValue(data)}
      >
        <div className="flex items-center gap-2">
          <div className="calendar-audit-user-select-label truncate">
            {userEmail}
          </div>
          {isExecEmail ? <ExecutiveLabel /> : null}
        </div>
      </div>
    );
  };

  /* Button row above table */
  const renderContentHeader = () => {
    return (
      <div className="flex items-center z-2">
        <DatePicker
          endDate={endDate}
          hasProposedChanges={hasProposedChanges}
          option={option}
          setEndDate={setEndDate}
          setOption={setOption}
          setStartDate={setStartDate}
          startDate={startDate}
        />
        <CustomSelectV2
          className="ml-2 mr-auto"
          components={{
            Option: renderUserOption,
            SingleValue: renderSelectedUser,
          }}
          isMulti={false}
          isSearchable={false}
          onChange={(option) => {
            if (!isEmptyObjectOrFalsey(option)) {
              if (hasProposedChanges) {
                setConfirmationModalData({
                  content:
                    "Are you sure you want to switch calendars?  Your existing changes will be lost.",
                  label: "Switch",
                  onClose: () => setConfirmationModalData(null),
                  onConfirm: () => {
                    if (!componentIsMounted.current) {
                      return;
                    }

                    setSelectedCalendar(option);
                    setConfirmationModalData(null);
                  },
                });
                return;
              }

              setSelectedCalendar(option);
            }
          }}
          options={primaryCalendars}
          styles={{
            control: (base) => ({ ...base, cursor: "pointer " }),
            menu: (base) => ({ ...base, width: "max-content", zIndex: 2 }),
            valueContainer: (base) => ({ ...base, flexWrap: "nowrap" }),
          }}
          value={selectedCalendar}
          variant={SELECT_VARIANTS.OUTLINED}
        />
        <div
          className="cursor-pointer flex items-center"
          onClick={() => {
            setPage(1);
            setOnlyShowRecurring(!onlyShowRecurring);
          }}
        >
          <DefaultSwitch
            isChecked={onlyShowRecurring}
            lightModeOverride={true}
          />
          <div className="line-height-16px ml-2 select-none">
            Only show recurring
          </div>
        </div>
        <CustomButtonV2
          buttonType={WHITE_BUTTON}
          className="ml-4 mr-2"
          disabled={isEmptyObjectOrFalsey(proposedChanges)}
          label={
            <div className="flex items-center">
              <UndoButton />
              <div className="ml-2">Revert all changes</div>
            </div>
          }
          onClick={handleRevertClick}
          removeDefaultFontStyles={true}
        />
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          disabled={isLoadingEvents}
          label={
            <div className="flex items-center">
              <Download size={14} />
              <div className="ml-2">Finish & Export</div>
            </div>
          }
          onClick={handleExportClick}
          removeDefaultFontStyles={true}
          shouldRenderSpinner={isExporting}
        />
      </div>
    );
  };

  /* Table */
  const renderContent = () => {
    const calendarCategories = useMemo(
      () =>
        outlookCategories[getCalendarUserEmail(selectedCalendar.value)] ?? [],
      [selectedCalendar],
    );

    return (
      <CalendarAuditCSVTable
        isLoadingEvents={isLoadingEvents}
        allCalendars={allCalendars}
        allLoggedInUsers={allLoggedInUsers}
        calendarCategories={calendarCategories}
        changeCount={changeCount}
        containerElement={containerRef.current}
        eventsToRender={eventsToRender}
        flashProposed={flashProposed}
        groupedRecurrences={groupedRecurrences}
        masterAccount={masterAccount}
        masterEventRecurrences={masterEventRecurrences}
        page={page}
        setChangeCount={setChangeCount}
        setPage={setPage}
        proposedChanges={proposedChanges}
        setProposedChanges={setProposedChanges}
        user={matchingUser}
      />
    );
  };

  return (
    <>
      <div
        className={classNames(
          CALENDAR_AUDIT_CLASSNAMES.CONTAINER,
          "white-mode",
        )}
        ref={containerRef}
      >
        {renderHeader()}
        <div className="calendar-audit-content-container">
          {renderContentHeader()}
          {renderContent()}
        </div>
        <OnePager data={onePagerData} pageRef={pageRef} />
      </div>
      <EventModalPopup
        isOpen={!isEmptyObjectOrFalsey(confirmationModalData)}
        onRequestClose={() => setConfirmationModalData(null)}
        hideHeader={true}
        skipDisablingHotKeys={true}
        style={determineDefaultModalStyle(false, false, {
          overlay: { zIndex: MODAL_OVERLAY_Z_INDEXES.AUDIT },
        })}
      >
        <CalendarAuditConfirmationModal
          content={confirmationModalData?.content ?? ""}
          label={confirmationModalData?.label ?? ""}
          onClose={confirmationModalData?.onClose ?? (() => null)}
          onConfirm={confirmationModalData?.onConfirm ?? (() => null)}
        />
      </EventModalPopup>
      <EventModalPopup
        isOpen={shouldDisplayCalendarAuditIntro(masterAccount) && !flashProposed}
        width={527}
        hideHeader={true}
        skipDisablingHotKeys={true}
        style={determineDefaultModalStyle(false, false, {
          overlay: { zIndex: MODAL_OVERLAY_Z_INDEXES.AUDIT },
        })}
      >
        <CalendarAuditIntroModal setFlashProposed={setFlashProposed} />
      </EventModalPopup>
    </>
  );
}

// Never re-render from outside changes
export default React.memo(CalendarAuditCSV, () => true);
