import classNames from "classnames";
import produce from "immer";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isSameDay } from "date-fns";
import { RefreshCw } from "react-feather";
import { Resizable } from "react-resizable";
import { useSelector } from "react-redux";

import {
  getEventAttendees,
  getEventCategories,
  getEventColorID,
  getEventCreatedAt,
  getEventEndJSDate,
  getEventStartJSDate,
  getEventUITitle,
  getEventUniqueEtag,
} from "../../services/eventResourceAccessors";
import {
  getEventDuration,
  getExternalInternalType,
  isAllDayEvent,
  isGoogleEvent,
  isOutOfOfficeEvent,
  isShowAsBirthdayEvent,
} from "../../lib/eventFunctions";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isTypeNumber,
} from "../../services/typeGuards";
import {
  blurAfterClick,
  hasEventPreventDefault,
} from "../../services/commonUsefulFunctions";
import { capitalizeFirstLetter, pluralize } from "../../lib/stringFunctions";
import { getRecurrenceFrequencyForAudit } from "../../lib/rruleFunctions";
import CustomButtonV2 from "../buttons/customButtonV2";
import { WHITE_BUTTON } from "../../services/globalVariables";
import CustomSelectV2, { SELECT_VARIANTS } from "../select/selectV2";
import {
  CALENDAR_AUDIT_SORT_BY,
  CALENDAR_AUDIT_SORT_BY_KEYS,
  constructAuditColumns,
  doesAuditColumnRequireZIndex,
  fillArrayToXElements,
  formatAuditCreatedAt,
  formatAuditEventMeetingTimeColumn,
  getEventProposedChanges,
} from "../../lib/calendarAudit/functions";
import { getAllTagsFromEvent } from "../../lib/tagsFunctions";
import Tags from "../tags";
import { getUserEmail, getUserProvider } from "../../lib/userFunctions";
import { DISPLAY_LOCATION_AUDIT } from "../tags/tagsVariables";
import {
  getColorHexFromColorObject,
  getColorNameFromColorObject,
  primaryEventsColors,
} from "../../services/googleColors";
import CircleWithColor from "../circleWithColor";
import {
  getCategoryColorHex,
  getCategoryDisplayName,
} from "../../services/categoryAccessors";
import {
  AuditColumn,
  EventToRender,
  getAuditColumnIsHidden,
  getAuditColumnLabel,
  getAuditColumnResizable,
  getAuditColumnType,
  isTypeOfGroupedRecurrence,
} from "../../lib/calendarAudit/accessors";
import { useColumns } from "../specialComponents/useColumns";
import { isOutlookUser } from "../../lib/outlookFunctions";
import usePrevious from "../specialComponents/usePrevious";
import useIntersectionObserver from "../../services/customHooks/useIntersectionObserver";
import {
  AUDIT_COLUMN_TYPES,
  CALENDAR_AUDIT_CLASSNAMES,
  CALENDAR_AUDIT_IDS,
  calendarAuditFrequencyOptions,
  DEFAULT_AUDIT_COLUMN_WIDTHS,
} from "../../lib/calendarAudit/variables";
import PurpleWarning from "../icons/calendarAudit/purpleWarning";
import HoverPortal from "../specialComponents/hoverPortal";
import SpinnerV2 from "../spinnerV2";

export default function CalendarAuditCSVTable({
  allCalendars,
  allLoggedInUsers,
  changeCount,
  calendarCategories,
  containerElement,
  eventsToRender,
  flashProposed,
  groupedRecurrences,
  masterAccount,
  masterEventRecurrences,
  page,
  proposedChanges,
  setChangeCount,
  setPage,
  setProposedChanges,
  user,
  isLoadingEvents,
}) {
  const dateFieldOrder = useSelector((state) => state.dateFieldOrder);
  const format24HourTime = useSelector((state) => state.format24HourTime);
  const [columns, setColumns] = useState(constructAuditColumns({ user })); // TODO: Add abiltiy to hide columns
  const { visibleColumns } = useMemo(() => {
    // TODO: Add hidden columns select
    const hiddenColumns = [] as AuditColumn[];
    const visibleColumns = [] as AuditColumn[];

    columns.forEach((column) => {
      getAuditColumnIsHidden(column)
        ? hiddenColumns.push(column)
        : visibleColumns.push(column);
    });

    return { hiddenColumns, visibleColumns };
  }, [columns]);
  const { columnWidths, getColumnWidth, handleResize } = useColumns(
    DEFAULT_AUDIT_COLUMN_WIDTHS,
  );
  const [isDurationSticking, setIsDurationSticking] = useState(false);
  const [isFrequencySticking, setIsFrequencySticking] = useState(false);
  const [sortKey] = useState(
    CALENDAR_AUDIT_SORT_BY_KEYS.MEETING_TIME_DESCENDING,
  ); // TODO: Add more sorting options
  const frequencyStickyRef = useRef(null);
  const durationStickyRef = useRef(null);
  const stickyContainerRef = useRef(null);
  const previousUser = usePrevious(user);
  const events = useMemo(() => {
    const filteredEvents: EventToRender[] = [];

    eventsToRender.forEach((eventOrGroupedRecurrence) => {
      const masterEventID = eventOrGroupedRecurrence.masterEventID;
      const occurrences = masterEventID
        ? groupedRecurrences[masterEventID]?.sort((eventA, eventB) =>
          getEventStartJSDate(eventA) > getEventStartJSDate(eventB) ? -1 : 1,
        )
        : null;
      const event = masterEventID ? occurrences[0] : eventOrGroupedRecurrence;

      /* Don't render the folling: */
      // 1) Multi day events
      // 2) All day events
      // 3) OOO and birthday events
      if (
        !isSameDay(getEventStartJSDate(event), getEventEndJSDate(event)) ||
        isAllDayEvent(event) ||
        isOutOfOfficeEvent(event) ||
        isShowAsBirthdayEvent(event)
      ) {
        return;
      }

      filteredEvents.push(eventOrGroupedRecurrence || event);
    });

    /* Sort events based on sortBy */
    if (CALENDAR_AUDIT_SORT_BY[sortKey]) {
      return CALENDAR_AUDIT_SORT_BY[sortKey]({
        filteredEvents,
        groupedRecurrences,
      });
    }

    /* Default to unsorted */
    return filteredEvents;
  }, [eventsToRender]);

  /* Check if duration is sticking */
  useIntersectionObserver({
    callback: useCallback(([e]) => {
      if (e.intersectionRatio < 1) {
        setIsDurationSticking(true);
      } else {
        setIsDurationSticking(false);
      }
    }, []),
    element: durationStickyRef.current,
    root: stickyContainerRef.current,
    rootMargin: "0px -116px 0px 0px",
    threshold: [1],
  });

  /* Check if frequency is sticking */
  useIntersectionObserver({
    callback: useCallback(([e]) => {
      if (e.intersectionRatio < 1) {
        setIsFrequencySticking(true);
      } else {
        setIsFrequencySticking(false);
      }
    }, []),
    element: frequencyStickyRef.current,
    root: stickyContainerRef.current,
    threshold: [1],
  });

  const getPageCount = () => {
    if (isEmptyArrayOrFalsey(events)) {
      return "0";
    }

    const startItem = page * 20 - 19;
    if (events.length < startItem + 19) {
      return `${startItem}-${events.length}`;
    }

    return `${startItem} - ${startItem + 19}`;
  };

  /* Change the color column name */
  useEffect(() => {
    if (getUserProvider(previousUser) !== getUserProvider(user)) {
      const updatedColumns = produce(columns, (draftState) => {
        return draftState.map((column) => {
          if (getAuditColumnType(column) === AUDIT_COLUMN_TYPES.COLOR) {
            return {
              ...column,
              label: isOutlookUser(user) ? "Categories" : "Color",
            };
          }

          return column;
        });
      });

      setColumns(updatedColumns);
    }
  }, [user]);

  /*********************/
  /* Handler Functions */
  /*********************/

  const handleDurationChange = ({ event, inputFieldEvent }) => {
    hasEventPreventDefault(inputFieldEvent);

    const updatedProposedChanges = produce(proposedChanges, (draftState) => {
      const uniqueEtag = getEventUniqueEtag(event);

      if (
        !draftState[uniqueEtag]?.frequency &&
        !draftState[uniqueEtag]?.frequencyLabel &&
        !inputFieldEvent.target.value
      ) {
        delete draftState[uniqueEtag];
        return;
      }

      draftState[uniqueEtag] = {
        ...draftState[uniqueEtag],
        duration: inputFieldEvent.target.value || null,
      };
    });

    setProposedChanges(updatedProposedChanges);
    incrementUsageCount();
  };

  const handleFrequencyChange = ({ event, option }) => {
    const updatedProposedChanges = produce(proposedChanges, (draftState) => {
      const uniqueEtag = getEventUniqueEtag(event);

      if (option.label === "--") {
        delete draftState[uniqueEtag];
        return;
      }

      draftState[uniqueEtag] = {
        ...draftState[uniqueEtag],
        frequency: option.value,
        frequencyLabel: option.label,
      };
    });

    setProposedChanges(updatedProposedChanges);
    incrementUsageCount();
  };

  const incrementUsageCount = () => {
    setChangeCount(changeCount + 1);
  };

  /******************/
  /* Render Methods */
  /******************/

  type ColumnProps = {
    children?: any;
    className?: string;
    isHeader?: boolean;
    key?: string;
    width?: number;
  };

  const renderColumn = ({
    children,
    className,
    isHeader = false,
    key,
    width,
  }: ColumnProps) => {
    const commonProps = {
      className,
      key,
      style: isHeader && isTypeNumber(width) ? { width } : {},
    };

    if (isHeader) {
      return <th {...commonProps}>{children}</th>;
    }

    return <td {...commonProps}>{children}</td>;
  };

  const renderHeaderOne = useCallback(() => {
    return (
      <thead className="calendar-audit-table-header-top-one">
        <tr>
          {visibleColumns.map((column) => {
            let className: string = CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER;
            let children;
            const columnType = getAuditColumnType(column);

            switch (columnType) {
              case AUDIT_COLUMN_TYPES.TITLE:
              case AUDIT_COLUMN_TYPES.ATTENDEES:
              case AUDIT_COLUMN_TYPES.COLOR:
              case AUDIT_COLUMN_TYPES.TAGS:
                className = classNames(
                  CALENDAR_AUDIT_CLASSNAMES.HEADER_Z_INDEX,
                  CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER,
                );
                break;
              case AUDIT_COLUMN_TYPES.CURRENT_DURATION:
                className = classNames(
                  CALENDAR_AUDIT_CLASSNAMES.BLUE_CELL,
                  CALENDAR_AUDIT_CLASSNAMES.LEFT_BORDER,
                  CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER,
                  "text-left",
                );
                break;
              case AUDIT_COLUMN_TYPES.CURRENT_FREQUENCY:
                className = classNames(
                  CALENDAR_AUDIT_CLASSNAMES.BLUE_CELL,
                  CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER,
                  "text-left",
                );
                break;
              case AUDIT_COLUMN_TYPES.PROPOSED_DURATION:
                className = classNames(
                  CALENDAR_AUDIT_CLASSNAMES.BLUE_CELL,
                  CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                  isDurationSticking
                    ? CALENDAR_AUDIT_CLASSNAMES.IS_STICKING
                    : "",
                  columnType,
                  "text-left",
                );
                children = "Duration";
                break;
              case AUDIT_COLUMN_TYPES.PROPOSED_FREQUENCY:
                className = classNames(
                  CALENDAR_AUDIT_CLASSNAMES.BLUE_CELL,
                  CALENDAR_AUDIT_CLASSNAMES.HEADER_Z_INDEX,
                  CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                  isFrequencySticking
                    ? CALENDAR_AUDIT_CLASSNAMES.IS_STICKING
                    : "",
                  columnType,
                  "text-left",
                );
                children = "Frequency";
                break;
              default:
                break;
            }

            return renderColumn({
              children,
              className,
              isHeader: true,
              key: columnType,
              width: getColumnWidth(columnType),
            });
          })}
        </tr>
      </thead>
    );
  }, [columnWidths, isDurationSticking, isFrequencySticking, visibleColumns]);

  const renderHeaderTwo = useCallback(() => {
    return (
      <thead className="calendar-audit-table-header-top-two">
        <tr>
          {visibleColumns.map((column) => {
            const columnType = getAuditColumnType(column);
            const currentWidth = getColumnWidth(columnType);
            const zIndexClassName = doesAuditColumnRequireZIndex(columnType)
              ? CALENDAR_AUDIT_CLASSNAMES.HEADER_Z_INDEX
              : "";

            if (getAuditColumnResizable(column)) {
              return (
                <Resizable
                  handle={
                    <span className="calendar-audit-table-resize-handle" />
                  }
                  height={0}
                  key={`calendar-audit-resize-header-${columnType}`}
                  onResize={(_, { size: { width } }) =>
                    handleResize({ columnType, updatedWidth: width })
                  }
                  width={currentWidth}
                >
                  <th
                    className={classNames(
                      CALENDAR_AUDIT_CLASSNAMES.HEADER_TWO,
                      zIndexClassName,
                    )}
                  >
                    {getAuditColumnLabel(column)}
                  </th>
                </Resizable>
              );
            }

            let refProp = {};
            let stickyClassName;

            if (columnType === AUDIT_COLUMN_TYPES.PROPOSED_DURATION) {
              refProp = { ref: durationStickyRef };

              stickyClassName = isDurationSticking ?
                classNames(
                  CALENDAR_AUDIT_CLASSNAMES.IS_STICKING,
                  CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                  columnType,
                ) :
                classNames(
                  CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                  columnType,
                );
            }

            if (columnType === AUDIT_COLUMN_TYPES.PROPOSED_FREQUENCY) {
              refProp = { ref: frequencyStickyRef };

              stickyClassName = isFrequencySticking ?
                classNames(
                  CALENDAR_AUDIT_CLASSNAMES.IS_STICKING,
                  CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                  columnType,
                ) :
                classNames(
                  CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                  columnType,
                );
            }

            return (
              <th
                className={classNames(
                  [
                    AUDIT_COLUMN_TYPES.CURRENT_DURATION as string,
                    AUDIT_COLUMN_TYPES.CURRENT_FREQUENCY as string,
                  ].includes(columnType)
                    ? CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER
                    : "",
                  CALENDAR_AUDIT_CLASSNAMES.HEADER_TWO,
                  stickyClassName,
                  zIndexClassName,
                  "font-medium",
                )}
                key={`calendar-audit-resize-header-${columnType}`}
                {...refProp}
              >
                {getAuditColumnLabel(column)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }, [
    columns,
    columnWidths,
    flashProposed,
    isDurationSticking,
    isFrequencySticking,
    visibleColumns,
  ]);

  const renderEventColors = (event) => {
    const googleColors = [primaryEventsColors[getEventColorID(event)]].filter(
      (color) => !isEmptyObjectOrFalsey(color),
    );
    const outlookColors =
      getEventCategories(event)?.map((displayName) =>
        calendarCategories.find((c) => c.displayName === displayName),
      ) ?? [];
    const colorsToUse = isGoogleEvent(event) ? googleColors : outlookColors;

    return (
      <div className="paint-colors-container audit dark-mode">
        <div className="flex items-center paint-colors-menu relative audit">
          {colorsToUse.map((colorObjectOrCategory, index) => (
            <div
              className="relative paint-colors-labels-container"
              key={`calendar-audit-${getEventUniqueEtag(event)}-color-${index}`}
            >
              <div className="flex paint-colors-label-container">
                <div className="flex items-center paint-colors-icon">
                  <CircleWithColor
                    color={
                      isGoogleEvent(event)
                        ? getColorHexFromColorObject(colorObjectOrCategory)
                        : getCategoryColorHex(colorObjectOrCategory)
                    }
                    colorName={null}
                    size={12}
                  />
                </div>
                <div className="paint-colors-label select-none">
                  {isGoogleEvent(event)
                    ? getColorNameFromColorObject(colorObjectOrCategory)
                    : getCategoryDisplayName(colorObjectOrCategory)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderBlankRow = ({ key }) => {
    return (
      <tr key={key}>
        {visibleColumns.map((column) => {
          let className = "";
          const columnType = getAuditColumnType(column);

          switch (columnType) {
            case AUDIT_COLUMN_TYPES.TITLE:
            case AUDIT_COLUMN_TYPES.MEETING_TIME:
            case AUDIT_COLUMN_TYPES.CREATED:
            case AUDIT_COLUMN_TYPES.ATTENDEES:
            case AUDIT_COLUMN_TYPES.INTERNAL_EXTERNAL:
            case AUDIT_COLUMN_TYPES.COLOR:
            case AUDIT_COLUMN_TYPES.TAGS:
              break;
            case AUDIT_COLUMN_TYPES.CURRENT_DURATION:
              className = classNames(
                CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER,
                "text-right",
              );
              break;
            case AUDIT_COLUMN_TYPES.PROPOSED_DURATION: {
              className = classNames(
                isDurationSticking
                  ? CALENDAR_AUDIT_CLASSNAMES.IS_STICKING
                  : "",
                CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                columnType,
                flashProposed
                  ? CALENDAR_AUDIT_CLASSNAMES.FLASHING_YELLOW_CELL
                  : "",
                "sticky text-center",
              );
              break;
            }
            case AUDIT_COLUMN_TYPES.CURRENT_FREQUENCY:
              className = CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER;
              break;
            case AUDIT_COLUMN_TYPES.PROPOSED_FREQUENCY: {
              className = classNames(
                isFrequencySticking
                  ? CALENDAR_AUDIT_CLASSNAMES.IS_STICKING
                  : "",
                CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                flashProposed
                  ? CALENDAR_AUDIT_CLASSNAMES.FLASHING_YELLOW_CELL
                  : "",
                columnType,
                "overflow-visible sticky text-center",
              );
              break;
            }
            default:
              break;
          }

          return renderColumn({
            children: null,
            className,
            key: columnType,
          });
        })}
      </tr>
    );
  };

  const renderEventRow = ({ inputEvent, masterEventID }) => {
    const occurrences = masterEventID
      ? groupedRecurrences[masterEventID]?.sort((eventA, eventB) =>
        getEventStartJSDate(eventA) > getEventStartJSDate(eventB) ? -1 : 1,
      )
      : null;
    const event = masterEventID ? occurrences[0] : inputEvent; // TODO: Update how we want to render recurring
    const { duration, frequency } = getEventProposedChanges({
      event,
      proposedChanges,
    });
    const key = `calendar-audit-table-event-${getEventUniqueEtag(event)}`;

    return (
      <tr key={key}>
        {visibleColumns.map((column) => {
          let children;
          let className = "";
          const columnType = getAuditColumnType(column);

          switch (columnType) {
            case AUDIT_COLUMN_TYPES.TITLE: {
              const updatedAt = new Date(getEventCreatedAt(event)).getTime();
              const today = new Date().getTime();
              const yearsAgo =
                Math.round(
                  ((today - updatedAt) / 1000 / 60 / 60 / 24 / 365) * 10,
                ) / 10;

              children = (
                <div className="w-full flex gap-2 items-center">
                  {yearsAgo >= 1 ? (
                    <HoverPortal
                      className="cursor-pointer flex items-center relative"
                      portalChildren="This event was created over a year ago. This may be an opportunity to revise or remove it."
                      portalClassName="background-color-modal-background-color more-information-modal"
                      portalDomNode={containerElement}
                      scrollElement={stickyContainerRef.current}
                    >
                      <PurpleWarning />
                    </HoverPortal>
                  ) : null}
                  <div className="line-height-16px size-min truncate">
                    {getEventUITitle(event)}
                  </div>
                  <div className="flex-shrink-0">
                    {isEmptyArrayOrFalsey(occurrences) ? (
                      <></>
                    ) : (
                      <HoverPortal
                        className="cursor-pointer flex items-center relative"
                        portalChildren={`${occurrences.length} recurring instances within date range`}
                        portalClassName="background-color-modal-background-color more-information-modal"
                        portalDomNode={containerElement}
                        scrollElement={stickyContainerRef.current}
                      >
                        <div className="flex items-center">
                          (<RefreshCw className="mr-1" size={10} />
                          {occurrences.length})
                        </div>
                      </HoverPortal>
                    )}
                  </div>
                </div>
              );
              break;
            }
            case AUDIT_COLUMN_TYPES.MEETING_TIME:
              children = formatAuditEventMeetingTimeColumn({
                dateFieldOrder,
                event,
                format24HourTime,
                rruleString: masterEventID
                  ? masterEventRecurrences[masterEventID]
                  : null,
              });
              break;
            case AUDIT_COLUMN_TYPES.CREATED:
              children = formatAuditCreatedAt({
                dateFieldOrder,
                event,
              });
              break;
            case AUDIT_COLUMN_TYPES.ATTENDEES: {
              const attendees = getEventAttendees(event)?.map(
                (attendee) => attendee.email || attendee.displayName,
              );

              children = (
                <HoverPortal
                  className="cursor-pointer flex items-center relative"
                  portalChildren={attendees?.map((attendee, index) => (
                    <div
                      className="truncate"
                      key={`calendar-audit-hover-attendee-${index}`}
                    >
                      {attendee}
                    </div>
                  ))}
                  portalClassName="attendee-more-information-modal background-color-modal-background-color more-information-modal"
                  portalDomNode={containerElement}
                  scrollElement={stickyContainerRef.current}
                >
                  <div className="w-full flex gap-2">
                    {attendees?.join(", ")}
                  </div>
                </HoverPortal>
              );
              break;
            }
            case AUDIT_COLUMN_TYPES.INTERNAL_EXTERNAL:
              children = capitalizeFirstLetter(
                getExternalInternalType({ event, masterAccount, user }),
              );
              break;
            case AUDIT_COLUMN_TYPES.COLOR:
              children = renderEventColors(event);
              break;
            case AUDIT_COLUMN_TYPES.TAGS:
              children = (
                <Tags
                  displayLocation={DISPLAY_LOCATION_AUDIT}
                  tags={getAllTagsFromEvent({
                    event,
                    currentUser: user,
                    user,
                    allLoggedInUsers,
                    masterAccount,
                    allCalendars,
                  })}
                  setTags={() => null}
                  userEmail={getUserEmail(user)}
                  isReadOnly={true}
                  matchingUser={user}
                />
              );
              break;
            case AUDIT_COLUMN_TYPES.CURRENT_DURATION:
              children = getEventDuration(event);
              className = classNames(
                CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER,
                "text-right",
              );
              break;
            case AUDIT_COLUMN_TYPES.PROPOSED_DURATION: {
              const hasDurationChanged = duration && duration !== getEventDuration(event);
              children = (
                <div className="flex items-center justify-center">
                  <input
                    className="rounded-md"
                    id={CALENDAR_AUDIT_IDS.PROPOSED_DURATION_INPUT}
                    onChange={(e) =>
                      handleDurationChange({ event, inputFieldEvent: e })
                    }
                    onWheel={(e) => blurAfterClick(e)}
                    type="number"
                    value={duration}
                  />
                </div>
              );
              className = classNames(
                isDurationSticking
                  ? CALENDAR_AUDIT_CLASSNAMES.IS_STICKING
                  : "",
                CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                columnType,
                hasDurationChanged ? CALENDAR_AUDIT_CLASSNAMES.BLUE_CELL : "",
                flashProposed
                  ? CALENDAR_AUDIT_CLASSNAMES.FLASHING_YELLOW_CELL
                  : "",
                "sticky text-center",
              );
              break;
            }
            case AUDIT_COLUMN_TYPES.CURRENT_FREQUENCY:
              children = getRecurrenceFrequencyForAudit(
                masterEventID ? masterEventRecurrences[masterEventID] : null,
              );
              className = CALENDAR_AUDIT_CLASSNAMES.HIDE_RIGHT_BORDER;
              break;
            case AUDIT_COLUMN_TYPES.PROPOSED_FREQUENCY: {
              const originalFrequency = getRecurrenceFrequencyForAudit(
                masterEventID ? masterEventRecurrences[masterEventID] : null,
              );
              const hasFrequencyChanged =
                frequency && originalFrequency !== getRecurrenceFrequencyForAudit(frequency);

              children = masterEventID ? (
                <CustomSelectV2
                  className="w-24"
                  isMulti={false}
                  isSearchable={false}
                  menuPortalTarget={stickyContainerRef.current}
                  menuPosition="fixed"
                  onChange={(option) =>
                    handleFrequencyChange({ event, option })
                  }
                  options={calendarAuditFrequencyOptions}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  value={calendarAuditFrequencyOptions.find(
                    (option) => option.value === frequency,
                  )}
                  variant={SELECT_VARIANTS.OUTLINED}
                />
              ) : null;
              className = classNames(
                isFrequencySticking
                  ? CALENDAR_AUDIT_CLASSNAMES.IS_STICKING
                  : "",
                CALENDAR_AUDIT_CLASSNAMES.STICKY_Z_INDEX,
                hasFrequencyChanged
                  ? CALENDAR_AUDIT_CLASSNAMES.BLUE_CELL
                  : "",
                flashProposed
                  ? CALENDAR_AUDIT_CLASSNAMES.FLASHING_YELLOW_CELL
                  : "",
                columnType,
                "overflow-visible sticky text-center",
              );
              break;
            }
            default:
              break;
          }

          /* If duration is set to 0, lower column opacity */
          /* At current time, these columns are the resizable ones */
          if (duration === "0" && getAuditColumnResizable(column)) {
            className = classNames(
              className,
              CALENDAR_AUDIT_CLASSNAMES.HALF_OPACITY,
            );
          }

          return renderColumn({
            children,
            className,
            key: columnType,
          });
        })}
      </tr>
    );
  };

  const renderEvents = useCallback(() => {
    const endIndex = page * 20;
    const startIndex = endIndex - 20;
    const slicedEvents = events.slice(startIndex, endIndex);

    return (
      <>
        {fillArrayToXElements({ array: slicedEvents, count: 20 }).map((eventToRender, index) => {
          if (isTypeNumber(eventToRender)) {
            return renderBlankRow({ key: index });
          }

          /* If not a grouped recurrence, then eventOrGroupedRecurrence is a VimcalEvent */
          return renderEventRow({
            inputEvent: eventToRender,
            masterEventID: isTypeOfGroupedRecurrence(eventToRender)
              ? eventToRender.masterEventID
              : null,
          });
        })}
      </>
    );
  }, [
    events,
    flashProposed,
    isDurationSticking,
    isFrequencySticking,
    masterEventRecurrences,
    page,
    proposedChanges,
  ]);

  const renderPagination = useCallback(() => {
    const shouldDisablePrevious = page === 1;
    const shouldDisableNext = !(events.length > page * 20);

    return (
      <div className="calendar-audit-pagination-container">
        <div className="calendar-audit-pagination-results-counter">
          <span className="calendar-audit-pagination-results-current">
            {getPageCount()}
          </span>{" "}
          of {events.length} {pluralize(events.length, "Result")}
        </div>
        <div className="calendar-audit-pagination-button-container">
          <CustomButtonV2
            buttonType={WHITE_BUTTON}
            className="mr-2"
            disabled={shouldDisablePrevious}
            label="Previous"
            onClick={() => {
              if (page - 1 < 1) {
                return;
              }

              setPage(page - 1);
            }}
            removeDefaultFontStyles={true}
          />
          <CustomButtonV2
            buttonType={WHITE_BUTTON}
            disabled={shouldDisableNext}
            label="Next"
            onClick={() => {
              if (events.length < (page + 1) * 20 - 19) {
                return;
              }

              setPage(page + 1);
            }}
            removeDefaultFontStyles={true}
          />
        </div>
      </div>
    );
  }, [events, page]);

  const renderBottomLoading = () => {
    if (!isLoadingEvents) {
      return null;
    }
    return (
      <div className="ml-1 flex items-center -mt-5">
        <SpinnerV2 variant="smallerThin" />
        <div className="ml-1 secondary-text-color default-font-size">
          Loading more results
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={CALENDAR_AUDIT_CLASSNAMES.TABLE_CONTAINER}
        ref={stickyContainerRef}
      >
        <table cellSpacing={0} cellPadding={0} className="calendar-audit-table">
          {renderHeaderOne()}
          {renderHeaderTwo()}
          <tbody>{renderEvents()}</tbody>
        </table>
      </div>
      {renderPagination()}
      {renderBottomLoading()}
    </>
  );
}
