import React from "react";
import SaveAndCancelButton from "../buttons/saveAndCancelButton";

type CalendarAuditConfirmationModalProps = {
  content: string;
  label: string;
  onClose: () => void;
  onConfirm: () => void;
};

export default function CalendarAuditConfirmationModal({
  content,
  label,
  onClose,
  onConfirm,
}: CalendarAuditConfirmationModalProps) {
  return (
    <div className="white-mode default-font-size">
      <div>
        {content}
      </div>
      <SaveAndCancelButton
        confirmButtonText={label}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </div>
  );
}
