import { AuditColumnWidth } from "./accessors";

export const CALENDAR_AUDIT_CLASSNAMES = {
  BLUE_CELL: "calendar-audit-table-placebo-blue-cell",
  CONTAINER: "calendar-audit-container",
  FLASHING_YELLOW_CELL: "calendar-audit-table-flashing-yellow-cell",
  HALF_OPACITY: "calendar-audit-cell-half-opacity",
  HEADER_TWO: "relative text-left",
  HEADER_Z_INDEX: "calendar-audit-table-header-z-index",
  HIDE_RIGHT_BORDER: "calendar-audit-table-hide-border-right",
  IS_STICKING: "is-sticking",
  LEFT_BORDER: "calendar-audit-table-border-left",
  STICKY_Z_INDEX: "calendar-audit-table-sticky-z-index",
  TABLE_CONTAINER: "calendar-audit-table-container",
} as const;

export const CALENDAR_AUDIT_IDS = {
  DATE_PICKER: "calendar-audit-date-picker",
  PROPOSED_DURATION_INPUT: "calendar-audit-duration-input",
} as const;

export const AUDIT_COLUMN_TYPES = {
  TITLE: "TITLE",
  MEETING_TIME: "MEETING_TIME",
  ATTENDEES: "ATTENDEES",
  INTERNAL_EXTERNAL: "INTERNAL_EXTERNAL",
  COLOR: "COLOR",
  TAGS: "TAGS",
  CREATED: "CREATED",
  CURRENT_DURATION: "CURRENT_DURATION",
  PROPOSED_DURATION: "PROPOSED_DURATION",
  CURRENT_FREQUENCY: "CURRENT_FREQUENCY",
  PROPOSED_FREQUENCY: "PROPOSED_FREQUENCY",
} as const;

export const calendarAuditFrequencyOptions = [
  { label: "--", value: null },
  { label: "Daily", value: "RRULE:FREQ=DAILY" },
  { label: "Weekdays", value: "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR" },
  { label: "Weekly", value: "RRULE:FREQ=WEEKLY" },
  { label: "Biweekly", value: "RRULE:FREQ=WEEKLY;INTERVAL=2" },
  { label: "Monthly", value: "RRULE:FREQ=MONTHLY" },
  { label: "Annually", value: "RRULE:FREQ=YEARLY" },
] as const;

export const DEFAULT_AUDIT_COLUMN_WIDTHS: AuditColumnWidth = {
  [AUDIT_COLUMN_TYPES.TITLE]: 256,
  [AUDIT_COLUMN_TYPES.MEETING_TIME]: 212,
  [AUDIT_COLUMN_TYPES.ATTENDEES]: 256,
  [AUDIT_COLUMN_TYPES.INTERNAL_EXTERNAL]: 128,
  [AUDIT_COLUMN_TYPES.COLOR]: 128,
  [AUDIT_COLUMN_TYPES.TAGS]: 128,
  [AUDIT_COLUMN_TYPES.CREATED]: 96,
  [AUDIT_COLUMN_TYPES.CURRENT_DURATION]: 96,
  [AUDIT_COLUMN_TYPES.PROPOSED_DURATION]: 96,
  [AUDIT_COLUMN_TYPES.CURRENT_FREQUENCY]: 96,
  [AUDIT_COLUMN_TYPES.PROPOSED_FREQUENCY]: 116,
} as const;

export const AUDIT_COLUMNS_REQUIRING_Z_INDEX_CLASSNAME = [
  AUDIT_COLUMN_TYPES.TITLE,
  AUDIT_COLUMN_TYPES.ATTENDEES,
  AUDIT_COLUMN_TYPES.COLOR,
  AUDIT_COLUMN_TYPES.TAGS,
  AUDIT_COLUMN_TYPES.PROPOSED_FREQUENCY,
] as string[];

export const CALENDAR_AUDIT_READY_MODAL_TOOLTIP = "CALENDAR_AUDIT_READY_MODAL_TOOLTIP" as const;
export const CALENDAR_AUDIT_INTRO_MODAL_TOOLTIP = "CALENDAR_AUDIT_INTRO_MODAL_TOOLTIP" as const;
