import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";

import PreloadResources from "../../components/preloadResources";
import { SIGNUP_TESTIMONIAL } from "../../services/experiments";
import { DEFAULT_FREE_TRIAL_LENGTH, VIMCAL_LOGO_CLEAR_BACKGROUND } from "../../services/globalVariables";
import { useUserCodes } from "../../services/stores/userData";
import { getMagicLinkConsumed, getMagicLinkEmail, getMagicLinkExpired, getMagicLinkInviteeName, getMagicLinkInviterName } from "../../services/maestro/maestroAccessors";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import MagicLinkSignupError from "../maestroLogin/components/magicLinkSignupError";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { getAccountName } from "../../lib/userFunctions";

const SLOTS_GIF_URL = "https://uploads-ssl.webflow.com/5f303e34821294abdf7d62ec/6164bde2d0380935cef20d10_availabilty-dark-cropped.gif";
const SLOTS_GIF = {
  url: SLOTS_GIF_URL,
  label: "The fastest way to share availability",
};

const TIME_TRAVEL_GIF_URL = "https://vimcal-app-images.s3.amazonaws.com/Time+Travel+LP.gif";
const TIME_TRAVEL_GIF = {
  url: TIME_TRAVEL_GIF_URL,
  label: "Easily schedule across time zones",
};

const FOCUS_MODE_GIF_URL = "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/focus-mode.png";
const FOCUS_MODE_GIF = {
  url: FOCUS_MODE_GIF_URL,
  label: "Enter focus mode to avoid distractions",
};

const NLP_GIF_URL = "https://vimcal-app-images.s3.amazonaws.com/NLP+LP.gif";

const ALEX_PROFILE_URL = "https://vimcal-app-images.s3.amazonaws.com/alexmccaw.jpg";
const ALEX_TESTIMONIAL = {
  name: "Alex MacCaw",
  url: ALEX_PROFILE_URL,
  quote:
    "Easily the best calendar I've used. They've also solved the awkward calendly link handoff. I'm blown away",
  company: "Co-founder of Clearbit",
};

const DICK_PROFILE_URL = "https://vimcal-app-images.s3.amazonaws.com/dickCostolo.jpg";
const DICK_TESTIMONIAL = {
  name: "Dick Costolo",
  url: DICK_PROFILE_URL,
  company: "Former CEO of Twitter",
  quote:
    "@Vimcal, call it Superhuman for calendar, new favorite productivity tool",
};

const YURI_PROFILE_URL = "https://vimcal-app-images.s3.amazonaws.com/yuri.jpg";
const YURI_TESTIMONIAL = {
  name: "Yuri Sagalov",
  url: YURI_PROFILE_URL,
  company: "YC Partner",
  quote: "My favorite calendar just keeps getting better.",
};

const GIF_ITEMS = [SLOTS_GIF, TIME_TRAVEL_GIF, FOCUS_MODE_GIF];
const ARROW_SHARED_CLASSNAME = "stroke-white cursor-pointer";
const TESTIMONIALS = [ALEX_TESTIMONIAL, DICK_TESTIMONIAL, YURI_TESTIMONIAL];
const PRELOADS = [
  SLOTS_GIF_URL,
  TIME_TRAVEL_GIF_URL,
  FOCUS_MODE_GIF_URL,
  NLP_GIF_URL,
  ALEX_PROFILE_URL,
  DICK_PROFILE_URL,
  YURI_PROFILE_URL,
];

export default function SignupPage({
  renderLoginButtonSection,
  signUpExperiment,
  renderPermissionsList,
  shouldDisplayPermissions,
  isMagicLink,
  magicLink,
  shouldDisplayMagicLinkError,
}) {
  const [infoIndex, setInfoIndex] = useState(0);
  const [displayAsColumn, setDisplayAsColumn] = useState(isColumnView());
  const [isUserSkipWaitList, setSkipWaitlist] = useState(false);
  const fromSource = useUserCodes(state => state.from);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const windowListener = () => {
    const updatedView = isColumnView();
    if (updatedView !== displayAsColumn) {
      setDisplayAsColumn(updatedView);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", windowListener);
    if (fromSource === "typeform") {
      setSkipWaitlist(true);
    }

    return () => {
      window.removeEventListener("resize", windowListener);
    };
  }, []);

  const onClickNext = () => {
    const items = isTestimonial() ? TESTIMONIALS : GIF_ITEMS;
    const newIndex = infoIndex + 1;
    if (newIndex >= items.length) {
      setInfoIndex(0);
    } else {
      setInfoIndex(newIndex);
    }
  };

  const onClickPrev = () => {
    const items = isTestimonial() ? TESTIMONIALS : GIF_ITEMS;
    const newIndex = infoIndex - 1;
    if (newIndex < 0) {
      setInfoIndex(items.length - 1);
    } else {
      setInfoIndex(newIndex);
    }
  };

  const renderTestimonial = () => {
    const { name, url, company, quote } = TESTIMONIALS[infoIndex];

    return (
      <div className="signup-left-panel">
        <div className="signup-testimonial-container text-white">
          <div className="mt-10 ml-14 mr-14 signup-page-testimonial-text-container">
            {`"${quote}"`}
          </div>

          <div
            className={classNames("flex justify-center items-center", "mt-10")}
          >
            <img
              alt=""
              src={url}
              className="signup-testimonial-profile-picture"
            />

            <div className="ml-4">
              <div className="font-size-20 font-weight-600">{name}</div>
              <div>{company}</div>
            </div>
          </div>
        </div>
        {renderSelector()}
      </div>
    );
  };

  const renderSelector = () => {
    const items = isTestimonial() ? TESTIMONIALS : GIF_ITEMS;
    return (
      <div className="flex items-center justify-center signup-page-navigator signup-carasoul-navigator-container">
        <ChevronLeft
          size={28}
          className={classNames(ARROW_SHARED_CLASSNAME, "mr-4")}
          onClick={onClickPrev}
        />
        {items.map((gif, index) => {
          return (
            <div
              key={`gif-selector-${index}`}
              onClick={() => setInfoIndex(index)}
              className={classNames(
                "rounded-full w-4 h-4 ml-2 mr-2 cursor-pointer",
                "bg-white",
                index === infoIndex
                  ? ""
                  : "opacity-30 hover:opacity-100 duration-300 ",
              )}
            ></div>
          );
        })}
        <ChevronRight
          size={28}
          className={classNames(ARROW_SHARED_CLASSNAME, "ml-4")}
          onClick={onClickNext}
        />
      </div>
    );
  };

  const renderFeatureGifs = () => {
    return (
      <div className="signup-left-panel">
        <div className="signup-page-gif-container mt-10 ml-10 mr-10">
          <img
            alt=""
            src={GIF_ITEMS[infoIndex].url}
            className="rounded-2xl signup-gif"
          />
        </div>

        <div className="text-white font-size-24 font-weight-500 mt-10 text-center">
          {GIF_ITEMS[infoIndex].label}
        </div>

        {renderSelector()}
      </div>
    );
  };

  const loginHalf = () => {
    return (
      <div className="w-full flex flex-col justify-center items-center select-none signup-login-layout mt-10 mb-10 mx-3">
        <img
          alt=""
          className="signup-page-logo"
          src={VIMCAL_LOGO_CLEAR_BACKGROUND}
        />
        <div className="text-white signup-create-account-font-size font-weight-500 mt-5 text-center">
          {isUserSkipWaitList ? "🎉 Congrats! You skipped the waitlist" : "Create an account"}
        </div>

        <div className="signup-create-account-subtitle font-weight-400 secondary-text-color mt-1">
          {`Let's get started with your ${DEFAULT_FREE_TRIAL_LENGTH} day free trial`}
        </div>

        <div className="mt-12">{renderLoginButtonSection()}</div>
      </div>
    );
  };

  const magicLinkLoginHalf = () => {
    const { firstName: magicLinkInviterFirst } = getMagicLinkInviterName({ magicLink });
    const magicLinkInviteeFirst = getAccountName({ masterAccount })?.firstName;
    const inviteeEmail = getMagicLinkEmail({ magicLink });

    /* Haven't fetched magic link yet */
    /* Or failed to fetch magic link */
    if (isEmptyObjectOrFalsey(magicLink) && shouldDisplayMagicLinkError) {
      return <MagicLinkSignupError errorType="invalid" />;
    }

    /* Magic link has been used */
    if (getMagicLinkConsumed({ magicLink })) {
      return <MagicLinkSignupError errorType="consumed" />;
    }

    /* Magic link expired (14 days from creation) */
    if (getMagicLinkExpired({ magicLink })) {
      return <MagicLinkSignupError errorType="expired" />;
    }

    return (
      <div className="w-full flex flex-col justify-center items-center select-none signup-login-layout mt-10 mb-10 mx-3">
        <img
          alt=""
          className="signup-page-logo"
          src={VIMCAL_LOGO_CLEAR_BACKGROUND}
        />
        <div className="text-white signup-create-account-font-size font-weight-500 mt-5 text-center">
          Hey{magicLinkInviteeFirst ? ` ${magicLinkInviteeFirst}!` : "!"}
        </div>
        <div className="signup-create-account-subtitle font-weight-400 secondary-text-color mt-1 w-7/12">
          {`${magicLinkInviterFirst} is inviting you to connect your work account (${inviteeEmail}) to Vimcal so they can schedule on your behalf.`}
        </div>
        <div className="mt-12">{renderLoginButtonSection()}</div>
      </div>
    );
  };

  const isTestimonial = () => {
    return signUpExperiment === SIGNUP_TESTIMONIAL;
  };

  const getLeftPanel = () => {
    if (isTestimonial()) {
      return renderTestimonial();
    }

    return renderFeatureGifs();
  };

  const permissionPanel = () => {
    if (!shouldDisplayPermissions) {
      return null;
    }

    return (
      <div className="vimcal-updated-login-permissions-frame vimcal-updated-login-show" style={{top: "33%"}}>
        {renderPermissionsList()}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "min-h-full min-w-full height-100vh signup-background-color items-center vimcal-updated-login-container",
        "signup-page-layout",
      )}
    >
      <PreloadResources resources={PRELOADS} />
      {displayAsColumn ? (
        <>
          { isMagicLink ? magicLinkLoginHalf() : loginHalf() }
          {getLeftPanel()}
        </>
      ) : (
        <>
          {getLeftPanel()}
          { isMagicLink ? magicLinkLoginHalf() : loginHalf() }
        </>
      )}
      {permissionPanel()}
    </div>
  );
}

function isColumnView() {
  return window.innerWidth < 1000;
}
